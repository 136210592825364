<template>
  <div class="px-2 rupst lg:px-0">
      <p class="mb-8 text-justify" v-html="tatakelolaData.rupst"></p>

      <h5 class="text-center" v-if="!Array.isArray(getDataTable) || !getDataTable.length"></h5>
      <div v-else>
            <div v-if="lang == 'id'">
                <DataTable
                    title=""
                    :columns="tableColumns"
                    :rows="whichLang"
                    :clickable="true"
                    :sortable="true"
                    :exactSearch="true"
                    :searchable="true"
                    :paginate="true"
                    :exportable="false"
                    :printable="false" 
    
                >
                <th slot="thead-tr">
                    Dokumen
                </th>
                <template slot="tbody-tr" slot-scope="props">
                    <td>
                        <button class="btn red darken-2 waves-effect waves-light compact-btn"
                            @click="e => openItem(props.row, e)">
                            <i class="material-icons text-white bg-secondary rounded-full p-1.5" style="font-size:16px;">insert_drive_file</i>
                        </button>
                    </td>
                </template>
            </datatable>
            </div>
            <div v-else>
                <DataTable
                    title=""
                    :columns="tableColumnsEng"
                    :rows="whichLang"
                    :clickable="true"
                    :sortable="true"
                    :exactSearch="true"
                    :searchable="true"
                    :paginate="true"
                    :exportable="false"
                    :printable="false" 
                >
                <th slot="thead-tr">
                    Document
                </th>
                    <template slot="tbody-tr" slot-scope="props">
                        <td>
                            <button class="btn red darken-2 waves-effect waves-light compact-btn"
                                @click="e => openItem(props.row, e)">
                                <i class="material-icons text-white bg-secondary rounded-full p-1.5" style="font-size:16px;">insert_drive_file</i>
                            </button>
                        </td>
                    </template>
                </datatable>
            </div>
      </div>
  </div>
</template>

<script>
import "material-design-icons-iconfont/dist/material-design-icons.css";
import DataTable from "vue-materialize-datatable";
import axios from 'axios'
import moment from 'moment'

export default {
  components: {
    DataTable
  },
  props:[
    'tatakelolaData',
    'lang'
  ],
  data() {
    return {
      tableColumns: [
          {
              label: "Tanggal Rilis",
              field: "date",
              numeric: false,
              html: false
          },
          {
              label: "Judul Dokumen",
              field: "name",
              numeric: false,
              html: false
          }
      ],
      tableColumnsEng: [
          {
              label: "Release Date",
              field: "date",
              numeric: false,
              html: false
          },
          {
              label: "Document Title",
              field: "name",
              numeric: false,
              html: false
          }
      ],
      getDataTable: null
    }
  },
  mounted() {
    axios
    .get('https://api.dharmagroup.co.id/api/report/datatable/rups?perpage=100', {
        'headers': { 'Authorization': 'Bearer 71063a7eab248181eafe665bf4ae346f-sFqsGThLIgEL9Kvjo0NZInsTou2NMoexYTnPwVjXAgV1K2scDuW58jBBy53'} })
    .then((res) => {
    //   console.log(res);
      this.getDataTable = res.data.data
    })
  },
  methods: {
    openItem(i, e) {
        window.open(i.filename);
        // console.log(i);
        // console.log(e);
    }
  },
  computed: {
    whichLang() {
        if (this.lang == 'id') {
            return this.newData
        } else {
            return this.newDataEng
        }
    },
    newData() {
        const data = this.getDataTable;
        moment.locale('id');

        let list = []


        for (let i = 0; i < data.length; i++) {

            const listData = {
                date:new Date(data[i].date).toLocaleDateString(),
                name:data[i].name,
                filename:data[i].filename
            }

            list.push({ ...listData});   
        }


        function compare( a, b ) {
            const date1 = new Date(a.date)
            const date2 = new Date(b.date)
            if ( date1 < date2 ){
                return 1;
            }
            if (date1 > date2 ){
                return -1;
            }
            
            return 0;
        }

        list.sort( compare );

        let list2 = []

        for (let i = 0; i < list.length; i++) {

            const listData = {
                date:moment(list[i].date).format('MM - DD - YYYY'),
                name:list[i].name,
                filename:list[i].filename
            }

            list2.push({ ...listData});   
        }
        

        return list2

       
    },
    newDataEng() {
        const data = this.getDataTable;
        moment.locale('en');

        let list = []


        for (let i = 0; i < data.length; i++) {

            const listData = {
                date:new Date(data[i].date).toLocaleDateString(),
                name:data[i].name_eng,
                filename:data[i].filename
            }

            list.push({ ...listData});   
        }


        function compare( a, b ) {
            const date1 = new Date(a.date)
            const date2 = new Date(b.date)
            if ( date1 < date2 ){
                return 1;
            }
            if (date1 > date2 ){
                return -1;
            }
            
            return 0;
        }

        list.sort( compare );

        let list2 = []

        for (let i = 0; i < list.length; i++) {

            const listData = {
                date:moment(list[i].date).format('MM - DD - YYYY'),
                name:list[i].name,
                filename:list[i].filename
            }

            list2.push({ ...listData});   
        }
        

        return list2
    }
  }
}
</script>

<style lang="scss">
    .rupst{
        .material-table{
            position: relative;
            padding-top: 72px !important;

            .table-header{
                border: none;
                width: 78px;
                position: absolute;
                right: -11px;
                top: 27px;
                z-index: 10;
                height: 48px;
            }
        }

        table {
            @apply w-full mt-5;

            thead{
                background-color: #312963;

                th{
                    @apply text-grays-50 text-base font-bold
                }

                // th:nth-child(1) {
                    
                //     width: 16.666667% !important;
                //     min-width: 100px !important;
                // }
                // th:nth-child(2) {
                //     width:auto;
                    
                // }
                // th:nth-child(3) {
                    
                //    width: 16.666667% !important;
                // }
                
                .sorting-desc, .sorting-asc{
                    @apply text-primary
                }
            }

            tbody{
                tr td {
                    @apply overflow-scroll
                }

                //  td:nth-child(3) {
                    
                //    text-align: center;
                // }
            }

           
        }

        #search-input-container{
            @apply absolute  my-2 border-none w-80 -right-3 top-5 mr-0;
            

            input{
                @apply  shadow rounded   p-3 w-full pr-10 outline-none;
                
            }

            #search-input{
                border:1px solid #212121 !important;
                height: 42px !important;
            }
        }

        
    }
</style>